<template>
  <CommonCalendarEventRouterView :userType="UserType.Agent" />
</template>

<script lang="ts">
import { CommonCalendarEventRouterView, UserType } from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    CommonCalendarEventRouterView,
  },
  setup() {
    return {
      UserType,
    }
  },
})
</script>

<style lang="scss" scoped></style>
